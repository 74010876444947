import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import API from 'libs/api-lib';
import { Button, Modal, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { QRCodeSVG } from 'qrcode.react';
import 'i18n';
import Icon from 'components/cmp_icon';
import Processing from 'components/cmp_processing';

import './cmp_oid4vp_presentation_request.css';


function CMP_OID4VP_PRESENTATION_REQUEST({ display, credential_id, onClose, processing = false }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');
    const [ var_request, set_request ] = useState(null);
    const [ var_error_message, set_error_message ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_focuselement, set_focuselement ] = useState(null);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_modal) {
            //  set focus to first element within the modal
            var_modal.querySelector('.modal__content').focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    let elements = var_modal.querySelectorAll('.modal__content, button:not([disabled])');
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === elements[0]) {
                            elements[elements.length - 1].focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === elements[elements.length - 1]) {
                            elements[0].focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (display) {
            set_focuselement(document.activeElement);
            set_modal(document.querySelector('#cmp_oid4vc_credential_offer'));
            create_request();
        } else if (var_focuselement) {
            var_focuselement.focus();
        }
    }, [display]);

    useEffect(() => {
        // Ping status API for state of offer
        if (display && var_request?.url && var_request?.status !== 'ERROR') {
            let interval = setInterval(() => {
                get_request_status();
            }, 3000); // 3 seconds
            return () => clearInterval(interval);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ display, var_request ]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function create_request() {
        set_processing(true);
        try {
            let request = await API_post_presentation_oid4vp_request();
            set_request({ ...request, status: 'REQUEST CREATED' });
            if (!request?.url) {
                set_error_message(t('We are unable to generate the QR code at this time due to an unexpected error. Please close this window and try again.'));
            }
        } catch (e) {
            console.error('Failed to generate QR');
            console.error(e);
            set_error_message(t('We are unable to generate the QR code at this time due to an unexpected error. Please close this window and try again.'));
        }
        set_processing(false);
    }

    async function get_request_status() {
        try {
            let status = await API_get_verification_request_status();
            set_request({ ...var_request, status });
            if (status === 'ERROR') {
                set_error_message(t('An error occurred while creating request. Please try again.'));
                set_processing(false);
            } else if (status === 'FAIL') {
                set_error_message(t('The presentation submission failed.'));
                set_processing(false);
            } else if (status === 'FULFILLED') {
                onClose_hide_QR();
            } else if (status !== 'REQUEST CREATED') {
                set_processing(true);
            }
        } catch (e) {
            console.error('Failed to retrieve request status');
            console.error(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------

    function API_post_presentation_oid4vp_request() {
        return API.post('issuer', '/post-presentation-oid4vp-request/', {
            body: { credential_id: credential_id }
        });
    }

    function API_get_verification_request_status() {
        return API.get('verifiable-credentials', `/get-verification-request-status/${var_request.token}`);
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onClose_hide_QR() {
        set_error_message('');
        onClose && onClose();
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            dimmer='inverted'
            onClose={onClose}
            open={display}
            closeOnEscape={true}
            closeOnDimmerClick={true}
            id='cmp_oid4vc_presentation_request'>

            <div className='modal__header'>
                <div>
                    <div className='modal__header__left'>
                        <div className='text--xl-medium'>{t('Request Presentation from Midy')}</div>
                    </div>
                </div>
            </div>

            <div className='modal__content'>
                {var_error_message &&
                    <Message error
                        icon={<Icon name='error' className='message__error__icon' />}
                        header={var_error_message}
                    />
                }
                <div className='modal__content__text text--sm-regular'>
                    {t('Scan the QR code below with your Midy Wallet.')}
                </div>
                {var_request?.url &&
                    <QRCodeSVG className='qr_svg'
                        value={var_request?.url}
                        size={200}
                    />
                }
            </div>

            <div className='modal__footer'>
                <div>
                    <Button className='secondary' onClick={onClose}>{t('Close')}</Button>
                </div>
            </div>
            <Processing display={var_processing} processingtext={t('Processing')} />
        </Modal>
    );

}

CMP_OID4VP_PRESENTATION_REQUEST.propTypes = {
    display: propTypes.bool.isRequired,
    credential_id: propTypes.string,
    onClose: propTypes.func
};

export default CMP_OID4VP_PRESENTATION_REQUEST;
