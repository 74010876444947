import React, { useEffect } from 'react';

import CRD_PASSWORD from './crd_password/crd_password';
import CRD_LANGUAGE from './crd_language/crd_language';



export default function TAB_SETTINGS({activetab, reset_authentication }) {

    //  variable declarations ------------------------------------------------------------------------------------------


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    // RENDER APP ======================================================================================================

    return(
        <>
            <CRD_PASSWORD activetab={activetab} />
            <CRD_LANGUAGE activetab={activetab} reset_authentication={reset_authentication} />
        </>
    )
};
