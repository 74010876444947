import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import auth from 'libs/auth-lib';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import 'i18n';

import './crd_password.css';



export default function CRD_CHANGEPASSWORD({ activetab }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const { t } = useTranslation('public');

    const [ var_sso_enabled, set_sso_enabled ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (activetab === 'SETTINGS') {
            populate_sso();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activetab]);



    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_sso() {
        let ssoenabled = await API_get_ssoenabled();
        set_sso_enabled(ssoenabled[0]?.ssoenabled === 'YES');
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_ssoenabled() {
        return API.get('admin', '/get-ssoenabled/' + auth.email.trim().toLowerCase());
    }

    //  event functions ------------------------------------------------------------------------------------------------


    function onClick_change_password() {
        auth.iam.change_password();
    }

    // RENDER APP ======================================================================================================

    return (

        <div id='crd__password'>

            <div className='card rounded-lg shadow-sm'>

                <div className='card__header'>
                    <div className='card__header__left text'>
                        <div id='hdr_password' className='text--xl-medium'>{t('Change password')}</div>
                        <div className='text--sm-regular'>{var_sso_enabled ? t('Password changes are disabled as your organization employs single sign-on.') : t('You will be redirected outside the application to change your password.')}</div>
                    </div>
                    <div className='card__header__right card__header__btns'>
                            <Button className={'secondary' + (var_sso_enabled ? ' disabled' : '')} disabled={var_sso_enabled} onClick={onClick_change_password}>{t('Change password')}</Button>
                    </div>
                </div>

                <div className='card__footer--mobilebtns'>
                    <Button className={'secondary' + (var_sso_enabled ? ' disabled' : '')} disabled={var_sso_enabled} onClick={onClick_change_password}>{t('Change password')}</Button>
                </div>
            </div>
        </div>
    );
}