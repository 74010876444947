import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import auth from 'libs/auth-lib';
import Icon from 'components/cmp_icon';
import 'i18n';

import CMP_BREADCRUMB from 'components/cmp_breadcrumb/cmp_breadcrumb';
import TABS from 'components/cmp_tabs/cmp_tabs';
import TAB_PROFILE from './tab_profile/tab_profile';
import TAB_SHARING from './tab_sharing/tab_sharing';
import TAB_SETTINGS from './tab_settings/tab_settings';

import './profile.css';


export default function PROFILE(appProps) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const navigate = useNavigate();
    const { t } = useTranslation('public');

    const [ var_tab, set_tab ] = useState('PROFILE');



    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //  async functions ------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_tab(var_activetab){
        set_tab(var_activetab);
    }

    function onClick_back() {
        navigate(-1);
    }

    // RENDER APP ======================================================================================================

    return(
        <div className='page details_card' id='profile'>
            <CMP_BREADCRUMB
                breadcrumb_elements={[]}
                current_page_text={t('Profile')}
            />
            <div id='content' tabIndex='0'>
                <div className='page__header'>
                    <h1 className='display--sm-regular'>{t('Profile')}</h1>
                    <div className='page__back'>
                        <Icon name='chevron_left' className='icon__page__back' alt={t('chevron left icon')} />
                        <div className='text--sm-regular' onClick={onClick_back}>{t('Back')}</div>
                    </div>

                    <div className='profile'>
                        <div className='profile__details'>
                            <div className='profile__avatar'>
                            </div>
                            <div className='text--xl-bold'>
                                {auth.firstname} {auth.lastname}
                            </div>
                        </div>
                    </div>

                </div>

                <TABS activetab={var_tab} onClick={onClick_tab}>
                    <TABS.TAB name='PROFILE' label={t('Profile')} />
                    <TABS.TAB name='SHARING' label={t('Sharing')} />
                    <TABS.TAB name='SETTINGS' label={t('Settings')} />
                </TABS>

                {var_tab === 'PROFILE' &&
                    <TAB_PROFILE activetab={var_tab} reset_profile={appProps.authenticatepageload} reset_authentication={appProps.reset_authentication} />
                }

                {var_tab === 'SHARING' &&
                    <TAB_SHARING />
                }

                {var_tab === 'SETTINGS' &&
                    <TAB_SETTINGS activetab={var_tab} reset_authentication={appProps.reset_authentication} />
                }
            </div>

        </div>
    )
};
